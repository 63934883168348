import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: () => import('../views/Login'),
    props: true,
    meta: {
      title: 'Comercial',
    },

  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: {
      login: true,
      title: "Bem vindo"
    },
    children: [
      {

        path: '/users',
        name: 'Usuarios',
        component: () => import('../views/Users'),
        meta: {
          title: "Comercial - Usuarios"
        }
      },
      {

        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile'),
        meta: {
          title: "Comercial - Perfil"
        }
      },
      {

        path: '/dash',
        name: 'DashBoard',
        component: () => import('../views/Dash'),
        meta: {
          title: "Comercial - Dashboard"
        }
      },
      {
    
        path: '/teams',
        name: 'Times',
        component: () => import('../views/Team/index'),
        meta: {
          title: "Comercial - Times"
        }
      },
      {
    
        path: '/goals',
        name: 'Metas',
        component: () => import('../views/Goals'),
        meta: {
          title: "Comercial - Metas"
        }
      },
      {
        path: '/saleperson',
        name: 'vendedor',
        component: () => import('../views/DashSalePerson'),
        meta: {
          title: "Comercial - Dashboard Vendedor"
        }
      },
      {
    
        path: '/sellers',
        name: 'Vendedores',
        component: () => import('../views/Seller'),
        meta: {
          title: "Comercial - Vendedores"
        }
      },
      {
        path: '/products',
        name: 'Produtos',
        component: () => import('../views/Product'),
        meta: {
          title: "Comercial - Produtos"
        }
      },
      {
        path: '/report',
        name: 'report',
        component: () => import('../views/Report'),
        meta: {
          title: "Comercial - Relatório"
        }
      },
      {
        path: '/bonus',
        name: 'bonus',
        component: () => import('../views/Bonus'),
        meta: {
          title: "Comercial - Bonificações"
        }
      },
      {
        path: '/period',
        name: 'Periodos',
        component: () => import('../views/Period'),
        meta: {
          title: "Comercial - Periodos"
        }
      },
      {
        path: '/seller_dash',
        name: 'SellerDASH',
        component: () => import('../views/sellerDialog'),
        meta: {
          title: "Comercial - Acompanhamento de vendedores"
        }
      },
      {
        path: '/coordinator',
        name: 'coordDASH',
        component: () => import('../views/DashCoord'),
        meta: {
          title: "Comercial - Bonificações Coordenador(a)"
        }
      }
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  if (to.matched.some(record => record.meta.login)) {

    if (!window.localStorage.token) {
      next("/")
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
