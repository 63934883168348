import Vue from 'vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import App from './App.vue'
import api from './services'
//import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
//import '@mdi/font/css/materialdesignicons.css'
import VueApexCharts from 'vue-apexcharts'
import DatetimePicker from 'vuetify-datetime-picker'
import { VueMaskDirective } from 'v-mask'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(DatetimePicker)

Vue.config.productionTip = false
Vue.prototype.$http = api
Vue.directive('mask', VueMaskDirective);

const options = {
  timeout: 6500
};

Vue.use(Toast, options)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
