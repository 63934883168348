import Axios from 'axios'

const axiosInstance = Axios.create({
    baseURL: `${process.env.VUE_APP_API}/api/v1/`,
  })
  
  axiosInstance.interceptors.request.use(
    function (config) {
      const authToken = window.localStorage.token;
      if (authToken) {
        config.headers.common['Authorization'] = `Bearer ${authToken
          }`
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  )
  
  export const api = {
    get(endpoint, config) {
      return axiosInstance.get(endpoint, config);
    },
    post(endpoint, body) {
      return axiosInstance.post(endpoint, body);
    },
    put(endpoint, body) {
      return axiosInstance.put(endpoint, body);
    },
    delete(endpoint) {
      return axiosInstance.delete(endpoint);
    },
    
    login(body) {
      return Axios.post(
        `${process.env.VUE_APP_API}/api/v1/auth`,
        body
      );
    // },
    // validaToken() {
    //   return axiosInstance.get(
    //     "verify_token"
    //   )
    // }
  }
}
  