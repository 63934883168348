<template>
  <v-app class="color">
   
      <router-view />
  </v-app>
</template>

<script>


export default {
  name: "App",

  data: () => ({
  }),
};
</script>
<style>
.color {
  background-color: #fafafa !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

</style>